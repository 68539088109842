<template>
  <div>
    <v-toolbar color="#0a227c">
      <div class="font-abel-20px white--text ml-3">Flight Times</div>

      <v-spacer></v-spacer>

      <div class="font-abel-20px ml-2" :style="{ color: '#19ff07' }">
        World Atlantic
      </div>
    </v-toolbar>

    <v-navigation-drawer v-model="showDrawer" absolute temporary>
      <v-list dense>
        <v-list-item @click="aboutDialog = true">
          <v-list-item-icon>
            <v-icon>info</v-icon>
          </v-list-item-icon>
          <!-- <v-list-item-content>
            <v-list-item-title v-text="'About'"></v-list-item-title>
          </v-list-item-content> -->
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <div class="spacer-vertical"></div>

    <v-container fluid>
      <v-dialog v-model="aboutDialog" max-width="350px">
        <div class="dialog-container font-abel-16px">
          <div class="d-flex justify-center mb-1">
            <v-img
              :max-width="150"
              :aspect-ratio="2.9"
              src="https://s3.amazonaws.com/flight-times.atlasair.com.resources/flight-times_logo.png"
              alt="flight-times logo"
            >
            </v-img>
          </div>

          <div class="d-flex justify-center font-abel-24px mb-5">
            Ground Ops Scheduling Software
          </div>

          <div class="d-flex justify-center mb-1">Developed By</div>
          <div class="d-flex justify-center mb-2">
            <v-img
              :max-width="150"
              :aspect-ratio="6.3"
              src="https://s3.amazonaws.com/flight-times.atlasair.com.resources/raiden_logo.png"
              alt="Raiden logo"
            >
            </v-img>
          </div>

          <!-- 
          <a class="font-abel-14px d-flex justify-center" href="mailto:info@raidensolutions.com">info@raidensolutions.com</a>
          <div class="font-abel-14px d-flex justify-center">1-1877-472-4336 / 1-877-4RAIDEN</div> -->
        </div>
      </v-dialog>

      <v-row dense>
        <v-col cols="1"> </v-col>

        <v-col cols="2">
          <div class="d-flex justify-start">
            <v-img
              :max-width="150"
              :aspect-ratio="2.9"
              src="https://s3.amazonaws.com/flight-times.atlasair.com.resources/flight-times_logo.png"
              alt="Atlas logo"
            >
            </v-img>
          </div>
        </v-col>

        <v-col cols="6"> </v-col>

        <v-col cols="2" justify="end">
          <div class="d-flex justify-end">
            <v-img
              :max-width="150"
              :aspect-ratio="2.9"
              src="https://s3.amazonaws.com/flight-times.atlasair.com.resources/atlas_logo_giant.png"
              alt="Atlas logo"
            >
            </v-img>
          </div>
        </v-col>

        <v-col cols="1"> </v-col>
      </v-row>

      <v-row dense justify="space-around">
        <v-col cols="10">
          <v-img
            :max-height="600"
            :aspect-ratio="16 / 9"
            src="https://s3.amazonaws.com/flight-times.atlasair.com.resources/atlas_747.jpg"
            alt="B747-8"
          >
            <div class="font-abel-24px white--text text-center mt-7">
              Welcome to Atlas Air’s Ground Ops Scheduling
            </div>

            <div class="d-flex justify-center font-abel-30px atlas-blue">
              <span v-if="!expired"
                >Next release in: {{ displayDays }} day(s)
                {{ displayHours }} hour(s) {{ displayMinutes }} min
                {{ displaySeconds }} sec
              </span>
              <span v-else>{{ `Delayed` }}</span>
            </div>
          </v-img>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="1"> </v-col>

        <v-col cols="10">
          <p class="font-abel-12px text-center">
            {{ disclaimer }}
          </p>
        </v-col>

        <v-col cols="1"> </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  mounted() {
    this.showRemaining();
  },
  data() {
    return {
      showDrawer: false,
      aboutDialog: false,
      expired: false,
      countDownDate: new Date("May 20, 2023 22:00:00").getTime(),
      displayDays: 0,
      displayHours: 0,
      displayMinutes: 0,
      displaySeconds: 0,
      disclaimer: `The contents of this program are considered Security Sensity Information that is controlled under the provisions of 49 CFR Parts 15 and 1520`,
    };
  },
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60;
    },
    _hours() {
      return this._minutes * 60;
    },
    _days() {
      return this._hours * 24;
    },
  },
  methods: {
    showRemaining() {
      const timer = setInterval(() => {
        const now = new Date();
        const distance = this.countDownDate - now.getTime();

        if (distance < 0) {
          clearInterval(timer);
          this.expired = true;
        }

        //console.log(distance)

        const days = Math.floor(distance / this._days);
        const hours = Math.floor((distance % this._days) / this._hours);
        const minutes = Math.floor((distance % this._hours) / this._minutes);
        const seconds = Math.floor((distance % this._minutes) / this._seconds);

        this.displayMinutes = minutes < 10 ? "0" + minutes : minutes;
        this.displaySeconds = seconds < 10 ? "0" + seconds : seconds;
        this.displayHours = hours < 10 ? "0" + hours : hours;
        this.displayDays = days < 10 ? "0" + days : days;
      }, 1000);
    },
  },
};
</script>

<style scoped>
.disclaimer {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
}

.home-image {
  border: 1px solid #bebebe;
  box-shadow: 3px 3px 3px #868383c9;
}

.frame {
  border: 1psx solid #bebebe;
  border-radius: 5px;
  box-shadow: 3px 3px 3px #868383c9;
  text-align: center;
  background-color: #0f2656;
  color: #ffb52e;
  width: 230px;
  margin: auto;
}

.dialog-container {
  padding: 10px;
  background-color: #f5f5f5;
}
</style>
