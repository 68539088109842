<template>
  <v-app>
    <v-main>
      <Home/>
    </v-main>
  </v-app>
</template>

<script>
import Home from './components/Home';

export default {
  name: 'App',

  components: {
    Home,
  },

  data: () => ({
    //
  }),
};
</script>

<style>

  .atlas-blue{
    color: #0f2656;
  }

  .header {
    position:fixed;
    top:0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width:100%;
    padding:0 10px;
    box-shadow: #a8a4a4 3px 3px 3px;
    z-index: 100;
    background-color: #0f2656

  }

  .spacer-vertical{
    height:60px;
  }

  .font-abel-12px {
    font-family: "Abel", Arial, Helvetica, sans-serif;
    font-size: 12px;
  }

  .font-abel-12px-bold {
    font-family: "Abel", Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: bold;
  }

  .font-abel-14px {
    font-family: "Abel", Arial, Helvetica, sans-serif;
    font-size: 14px;
  }

  .font-abel-16px {
    font-family: "Abel", Arial, Helvetica, sans-serif;
    font-size: 16px;
  }

  .font-abel-16px-bold {
    font-family: "Abel", Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
  }

  .font-abel-20px {
    font-family: "Abel", Arial, Helvetica, sans-serif;
    font-size: 20px;
  }

  .font-abel-20px-bold {
    font-family: "Abel", Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: bold;
  }

  .font-abel-24px {
    font-family: "Abel", Arial, Helvetica, sans-serif;
    font-size: 24px;
  }

  .font-abel-24px-bold {
    font-family: "Abel", Arial, Helvetica, sans-serif;
    font-size: 24px;
    font-weight: bold;
  }

  .font-abel-30px {
    font-family: "Abel", Arial, Helvetica, sans-serif;
    font-size: 30px;
  }

</style>
